import React from 'react'

const Navbar = () => {
    return (
        <div className = "w-screen h-[70px] mx-auto flex justify-between items-center p-4 fixed top-0 bg-black">
            <div className = "w-full flex items-center justify-start md:justify-center">
                <p className="[font-family:'Josefin_Sans-Bold',Helvetica] font-bold text-transparent text-[36px] tracking-[0] leading-[normal]">
                    <span className="text-[#f52121] text-[50px]">1</span>
                    <span className="text-white">Punter</span>
                    <span className="text-[#f52121] text-[60px]">.</span>
                    <span className="text-[#f52121]">com</span>
                </p>
            </div>
        </div>
    )
}

export default Navbar
