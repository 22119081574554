import React from 'react'
import {AiTwotoneStar} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";




const TopPunters = ({punters}) => {

    const navigate = useNavigate();

    const goRouteId = (id) => {
    navigate(`/details/${id}`);
    } 

    return (
        <div className = "bg-[#1B1B1B] rounded-xl md:mx-14 pb-20 ">
            <div className = "mt-[60px] p-[23px]  ">
                <p className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-[28px] tracking-[0] leading-[normal]">
                    Top Punters
                </p>

                <p className = " mt-[10px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#707070] text-[20px] tracking-[0] leading-[normal]">
                    We curated a list of top punters and their predictions to help you stay ahead
                </p>
            </div>

            <div>
            <table className = "w-full mt-6 md:mt-12">
                <thead className = "mb-10">
                    <tr className = "text-start  [font-family:'Inter-Regular',Helvetica] font-normal text-[#707070] text-[17px] md:text-[20px]  tracking-[0] leading-[normal]">
                        <th className = "text-start px-6 ">Punter</th>
                        <th className = "text-start">Nationality</th>
                        <th className = "text-start px-6">Accuracy</th>
                    </tr>
                </thead>
                {/* <div className = "mt-5"></div> */}
                <tbody className = "text-[#EEEEEE] ">
                {
                    punters.map(punter => {
                        return (
                                <tr key={punter.id} className = " hover:bg-[#F52222]" onClick={() => goRouteId(punter.id)}>
                                    <td className = "px-6 my-4 text-xl">{punter.name}</td>
                                    <td className = " text-xl">{punter.nationality}</td>
                                    <td className = "px-6 my-6 text-sm md:text-xl flex text-[#B5941E]">
                                        <AiTwotoneStar /> 
                                        <AiTwotoneStar /> 
                                        <AiTwotoneStar /> 
                                        <AiTwotoneStar /> 
                                        <AiTwotoneStar /> 
                                    </td>
                                </tr>
                           
                        )
                    })
                }
                </tbody>
                
            </table>
            </div>
        </div>
    )
}

export default TopPunters
