import {React, useState} from 'react'
import { useNavigate } from "react-router-dom";

import {AiOutlineArrowLeft} from "react-icons/ai"
import {IoCopyOutline} from "react-icons/io5"
import {CopyToClipboard} from 'react-copy-to-clipboard';





const Puntergames = ({games}) => {

    const navigate = useNavigate();

    const returnHome = (id) => {
    navigate(`/`);
    } 

    const [copyBounce, setCopyBounce] = useState(false)

    const handleCopy = () => {
        // console.log("teis text has been copied")
        setCopyBounce(true)
        console.log(games)

        setTimeout(() => {
            setCopyBounce(false);
            }, 2000);
    }

    return (
        <div className = "bg-[#1B1B1B] rounded-xl md:mx-14 py-[20px] md:py-[60px] md:mt-[30px]">
            <div className = "flex items-center">
                <button className = "outline-none border-none" onClick={returnHome}>
                    <AiOutlineArrowLeft size = {25} className = "ml-1 md:mx-4 font-semibold"/>
                </button>
                <p className = "[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-[24px] tracking-[0] leading-[normal]">
                    {games.name}
                </p>
            </div>

            <div className =" md:mx-14 mt-[10px] p-3">
                { games.ticket.map(ticket => {
                    return(
                        <div className ="mt-[35px] md:mt-[65px] bg-black p-4 md:p-8 rounded-md" key = {ticket.id}>
                            <p className = "[font-family:'Inter-Bold',Helvetica] font-bold text-white text-[18px] tracking-[0] leading-[normal]">
                                {ticket.odds} Odds - Posted {new Date(ticket.created_at).toLocaleDateString()}
                            </p>
                            <p className = " mt-[10px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#707070] text-[20px] tracking-[0] leading-[normal]">
                                {ticket.description}
                            </p>

                            <table className = "w-full mt-6 md:mt-[30px]">
                                <thead className = "mb-10">
                                    <tr className = "text-start  [font-family:'Inter-Regular',Helvetica] font-normal text-[#707070] text-[16px] md:text-[16px]  tracking-[0] leading-[normal]">
                                        <th className = "text-start  ">Bet Platform</th>
                                        <th className = "text-start">Bet Code</th>
                                        <th className = "text-start ">Copy</th>
                                        <th className = "text-start ">Play</th>
                                    </tr>
                                </thead>
                                    <tbody className = "text-[#EEEEEE] mt-5">
                                        <tr className = "mt-[10px] h-[40px] rounded-md hover:bg-[#F52222] [font-family:'Inter-Regular',Helvetica] font-normal text-[#eeeeee] text-[18px] tracking-[0] leading-[normal] whitespace-nowrap">
                                            <td>SportyBet</td>
                                            <td className = "uppercase">{ticket.sporty}</td>
                                            <td className ="">
                                                <CopyToClipboard onCopy = {handleCopy} text = {ticket.sporty.toUpperCase()} >
                                                    <IoCopyOutline  />
                                                </CopyToClipboard> 
                                            </td>
                                            <td>
                                                <a 
                                                    href = {`https://www.sportybet.com/ng/?shareCode=${ticket.sporty}`}
                                                    target="_blank"
                                                    className = "p-1 md:w-10 rounded-lg text-[8px] md:text-[9px] font-semibold flex flex-col items-center bg-[#0a9737]">
                                                    <p>Open </p>
                                                    <p>Sporty</p>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                
                            </table>
                        </div>
                    )
                })}
            </div>

            <div className = {`${copyBounce? "flex" : "hidden"} animate-bounce w-[250px] h-[50px] rounded-lg text-center flex items-center justify-center fixed top-[90px] right-3 bg-[#202020] text-white`}>
                <p className = "[font-family:'Inter-Bold',Helvetica] font-bold text-white text-[18px] tracking-[0] leading-[normal]"> Betting Code Copied</p>
            </div>
        </div>
    )
}

export default Puntergames
