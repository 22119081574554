import {React, useEffect, useState} from 'react'
import axios from "axios";

import TopPunters from '../components/TopPunters'


const Homepage = () => {

    const [punters, setPunters] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {
        axios
            .get("https://punting-backend-4qak.onrender.com/v1/api/punter")
            .then((res) => {    
                setPunters(res.data)
                })
            .catch(err => {
            setError(err.message);
            })
       
    }, [])


    return (
        <div className = "pt-[90px] pb-20 flex w-full h-screen bg-black">
            <div className = "hidden md:flex md:w-1/4">
            </div>
            {/* AD2 */}
            <div className = "w-full md:w-2/4 px-4">
                <TopPunters punters = {punters} />
            </div>

            <div className = "hidden md:flex md:w-1/4">
            {/* AD 2 */}
            </div>
        </div>
    )
}

export default Homepage
