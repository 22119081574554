import {React, useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import axios from "axios";

import Puntergames from '../components/Puntergames'

const DetailsPage = () => {

    const {id} = useParams()
    const url = "https://punting-backend-4qak.onrender.com/v1/api/ticket/" + id
    const [puntersGames, setPuntersGames] = useState({ticket:[]})
    const [error, setError] = useState("")

    useEffect(() => {
        axios
            .get(url)
            .then((res) => {    
                setPuntersGames(res.data)
                // console.log(puntersGames)
                console.log(res.data)
                })
            .catch(err => {
            setError(err.message);
            })
       
    }, [])

    return (
        <div className = "pt-[120px] md:pt-[90px] flex w-full h-full lg:h-full pb-[1000px]  bg-black">
            <div className = "hidden md:flex md:w-1/4 text-white">
               {puntersGames.length}
            </div>
            {/* AD2 */}
            <div className = "w-full mb-[100px] md:w-2/4 px-4 text-white">
               { <Puntergames games = {puntersGames} />}
            </div>

            <div className = "hidden md:flex md:w-1/4">
            {/* AD 2 */}
            </div>
        </div>
    )
}

export default DetailsPage
