import Navbar from "./components/Navbar"
import HomePage from "./pages/Homepage"
import DetailsPage from "./pages/DetailsPage"
import {createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Routes} from "react-router-dom"

const route = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/details",
    element: <HomePage />
  },
  {
    path: "/details/:id",
    element: <DetailsPage />
  }
]
  
)

// const route = createBrowserRouter(
//   createRoutesFromElements(
//       <Route path="/" element={<HomePage />} />
//       <Route path="details/:id" element={<DetailsPage />} />
    
    
    
//   )
// )


function App() {
  return (
    <div className="App h-full">
      <Navbar />
      <RouterProvider router={route} />
    </div>
  );
}

export default App;
